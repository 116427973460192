(function ($) {
  "use strict";

  $(window).on('scroll load', function () {
    if ($(".navbar").offset().top > 20) {
      $(".fixed-top").addClass("top-nav-collapse");
    } else {
      $(".fixed-top").removeClass("top-nav-collapse");
    }
  });


  $(function () {
    $(document).on('click', 'a.scrolling-page', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top
      }, 600, 'easeInOutExpo');
      event.preventDefault();
    });
  });

  // closes the responsive menu on menu item click
  $(".navbar-nav li a").on("click", function () {
    if (!$(this).parent().hasClass('dropdown'))
      $(".navbar-collapse").collapse('hide');
  });


  /* Back To Home */
  var amountScrolled = 700;
  $(window).scroll(function () {
    if ($(window).scrollTop() > amountScrolled) {
      $('a.back-to-home').fadeIn('500');
    } else {
      $('a.back-to-home').fadeOut('500');
    }
  });


  /* Removes Long Focus On Buttons */
  $(".button, a, button").mouseup(function () {
    $(this).blur();
  });

})(jQuery);


function getMessanger() {
  var Messenger = function (element) {
    'use strict';
    var messangerVar = this;

    messangerVar.init = function () {
      messangerVar.codeletters = "@§$%+?£&#*";
      messangerVar.message = 0;
      messangerVar.current_length = 0;
      messangerVar.fadeBuffer = false;
      messangerVar.messages = [
        'Another step in the world of tomorrow'
      ];
      setTimeout(messangerVar.animateIn, 100);
    };

    messangerVar.generateRandomString = function (length) {
      var randomText = '';
      while (randomText.length < length) {
        randomText += messangerVar.codeletters.charAt(Math.floor(Math.random() * messangerVar.codeletters.length));
      }
      return randomText;
    };

    messangerVar.animateIn = function () {
      if (messangerVar.current_length < messangerVar.messages[messangerVar.message].length) {
        messangerVar.current_length = messangerVar.current_length + 2;
        if (messangerVar.current_length > messangerVar.messages[messangerVar.message].length) {
          messangerVar.current_length = messangerVar.messages[messangerVar.message].length;
        }
        var message = messangerVar.generateRandomString(messangerVar.current_length);
        $(element).html(message);
        setTimeout(messangerVar.animateIn, 20);
      } else {
        setTimeout(messangerVar.animateFadeBuffer, 20);
      }
    };

    messangerVar.animateFadeBuffer = function () {
      if (messangerVar.fadeBuffer === false) {
        messangerVar.fadeBuffer = [];
        for (var i = 0; i < messangerVar.messages[messangerVar.message].length; i++) {
          messangerVar.fadeBuffer.push({
            c: (Math.floor(Math.random() * 12)) + 1,
            l: messangerVar.messages[messangerVar.message].charAt(i)
          });
        }
      }
      var startCycles = false;
      var message = '';

      for (var y = 0; y < messangerVar.fadeBuffer.length; y++) {
        var fader = messangerVar.fadeBuffer[y];
        if (fader.c > 0) {
          startCycles = true;
          fader.c--;
          message += messangerVar.codeletters.charAt(Math.floor(Math.random() * messangerVar.codeletters.length));
        } else {
          message += fader.l;
        }
      }
      $(element).html(message);

      if (startCycles === true) {
        setTimeout(messangerVar.animateFadeBuffer, 50);
      } else {
        setTimeout(messangerVar.cycleText, 2000);
      }
    };

    messangerVar.cycleText = function () {
      messangerVar.message = messangerVar.message + 1;
      if (messangerVar.message >= messangerVar.messages.length) {
        messangerVar.message = 0;
      }
      messangerVar.current_length = 0;
      messangerVar.fadeBuffer = false;
      $(element).html('.');
      setTimeout(messangerVar.animateIn, 0);
    };
    messangerVar.init();
  }

  new Messenger($('#messenger'));
}

function startPreloader() {
  var preloaderFadeOutTime = 500;

  function hidePreloader() {
    var preloader = $('.load-wrapper');
    setTimeout(function () {
      preloader.fadeOut(preloaderFadeOutTime);
    }, 500);
  }

  hidePreloader();
  $('.back-to-home').click();
}

function counterFunction() {
  var a = 0;
  $(window).scroll(function () {
    if ($('#counter').length) {
      var oTop = $('#counter').offset().top - window.innerHeight;
      if (a === 0 && $(window).scrollTop() > oTop) {
        $('.counter-value').each(function () {
          var $this = $(this),
            countTo = $this.attr('data-count');
          $({
            countNum: $this.text()
          }).animate({
              countNum: countTo
            },
            {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
              }
            });
        });
        a = 1;
      }
    }
  });
}
